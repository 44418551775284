// Pls Maintain the same indentation
export const WhatsappTemplate = (msg, leadStatus, category) => {
const status = {
'CALL_BACK': `
Halo Bpk/Ibu ${msg.customerName},

Dengan senang hati saya ${msg.agentName} membantu dalam menemukan asuransi
terbaik sesuai kebutuhan dan anggaran anda.

Kami ingin memberitahukan bahwa promo terbatas "Jaminan Harga
Termurah" hanya berlaku sampai hari ini. Apabila anda membutuhkan
perlindungan terbaik, kami dengan senang hati merekomendasikan beberapa
pilihan Asuransi ${category} 🔔📣

Mengapa perlu beli di Lifepal?
1️⃣ Harga Termurah

2️⃣ Perbandingan semua merek di pasar

3️⃣ Konsultasi terbaik dari ahli

4️⃣ Bantuan klaim 24/7 dari Lifepal, apabila terjadi kendala saat claim asuransi.
Kami akan senantiasa membantu anda

Apakah ada pertanyaaan atau bisa saya bantu rekomendasi pak?

Stay Safe,
${msg.agentName} 💙
`,
}
return status[leadStatus];
};
