import { idr } from "./currency";
import { WhatsappTemplate } from "./whatsapp.template";
import { categoryLib } from 'clientUtils'
const InsuranceCategory = {
  HEALTH_INDIVIDU: 'HEALTH_INDIVIDU',
  HEALTH_BUSINESS: 'HEALTH_BUSINESS',
  HEALTH_FAMILY: 'HEALTH_FAMILY',
  HEALTH_SHARIA: 'HEALTH_SHARIA',
  HEALTH_EMPLOYEE: 'HEALTH_EMPLOYEE',
  AUTO_CAR: 'AUTO_CAR',
  LIFE: 'LIFE',
  PERSONAL_ACCIDENT: 'PERSONAL_ACCIDENT',
  MICRO: 'MICRO',
  PENYAKIT_KRITIS: 'PENYAKIT_KRITIS',
  TRAVEL: 'TRAVEL',
  BPJS_PLUS: 'BPJS_PLUS',
  CRITICAL_ILLNESS: 'CRITICAL_ILLNESS',
  MOTO: 'AUTO_CAR',
  HEALTH: 'HEALTH_INDIVIDU',
};

const CategoryPremi = {
  'health': {
    maxPremi: 82500000,
    budgetPerMonth: Math.round(82500000 / 12),
  },
  'life': {
    maxPremi: 57500000,
    budgetPerMonth: Math.round(57500000 / 12),
  },
  'personal-accident': {
    maxPremi: 57500000,
    budgetPerMonth: Math.round(57500000 / 12),
  },
  'micro-rawat-inap': {
    maxPremi: 4500000,
    budgetPerMonth: Math.round(4500000 / 12),
  },
  'syariah': {
    maxPremi: 3900000,
    budgetPerMonth: Math.round(3900000 / 12),
  },
  'travel': {
    maxPremi: 2600000,
    budgetPerMonth: Math.round(2600000 / 12),
  },
  'property': {
    maxPremi: 4500000,
    budgetPerMonth: Math.round(4500000 / 12),
  },
  'gadget': {
    maxPremi: 4500000,
    budgetPerMonth: Math.round(4500000 / 12),
  },
  'bike': {
    maxPremi: 4500000,
    budgetPerMonth: Math.round(4500000 / 12),
  },
  'pet': {
    maxPremi: 4500000,
    budgetPerMonth: Math.round(4500000 / 12),
  },
};

const InvoiceStatus = {
  PAID: 'PAID',
  UNPAID: 'UNPAID',
  GRACE_PERIOD: 'GRACE_PERIOD',
  DEACTIVATED: 'DEACTIVATED',
  PROCESSING: 'PROCESSING',
};

const Gender = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};

const GenderPlaceholder = {
  MALE: 'Pria',
  FEMALE: 'Wanita',
};

const Spouse = {
  MALE: 'Suami',
  FEMALE: 'Istri',
};

const CancelReason = [
  { label: 'Berubah Pikiran', value: 'Berubah Pikiran' },
  { label: 'Informasi Salah', value: 'Informasi Salah' },
  { label: 'Keuangan tidak stabil', value: 'Keuangan tidak stabil' },
  { label: 'Permasalahan Layanan', value: 'Permasalahan Layanan' },
  { label: 'Perubahan Premium', value: 'Perubahan Premium' },
  { label: 'Rekening tidak valid', value: 'Rekening tidak valid' },
  { label: 'Rumah sakit Jauh', value: 'Rumah sakit Jauh' },
  { label: 'Tidak Pernah Setuju Untuk Membeli', value: 'Tidak Pernah Setuju Untuk Membeli' },
  { label: 'Turun Naik Plan', value: 'Turun Naik Plan' },
  { label: 'Ada Solusi Lebih Murah', value: 'Ada Solusi Lebih Murah' },
  { label: 'Pasangan Tidak Setuju', value: 'Pasangan Tidak Setuju' },
  { label: 'Others', value: 'Others' },
];

const PriceAlias = {
  '-1': 'Tersedia',
  '-2': 'Tak terbatas',
  '-3': 'Termasuk di atas',
  '-4': '-',
  '-5': ' ',
};

const ApplicationStage = {
  // QUOTATION: 'QUOTATION',
  SELECT_PLAN: 'SELECT_PLAN',
  POLICY_INFO: 'POLICY_INFO',
  DECLARATION: 'DECLARATION',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_PROCESSING: 'PAYMENT_PROCESSING',
  PAYMENT_COMPLETE: 'PAYMENT_COMPLETE',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  REFUND_PROCESSING: 'REFUND_PROCESSING',
  REFUND_COMPLETE: 'REFUND_COMPLETE',
  REFUND_FAILED: 'REFUND_FAILED',
  POLICY_ISSUED: 'POLICY_ISSUED',
  POLICY_DEACTIVATED: 'POLICY_DEACTIVATED',
};

const ApplicationStatus = {
  SELECT_PLAN: 'Pilih Paket',
  POLICY_INFO: 'Mengisi Pernyataan',
  DECLARATION: 'Menunggu Pembayaran',
  PAYMENT_PENDING: 'Menunggu Pembayaran',
  PAYMENT_PROCESSING: 'Memproses Pembayaran',
  PAYMENT_COMPLETE: 'Menunggu Detil Informasi',
  PAYMENT_FAILED: 'Pembayaran Gagal',
  REFUND_COMPLETE: 'Refund Berhasil',
  REFUND_FAILED: 'Refund Gagal',
  REFUND_PROCESSING: 'Refund Diproses',
  APP_CANCELED: 'Dibatalkan',
  POLICY_SUBMIT: 'Mengajukan Polis',
  POLICY_ISSUED: 'Polis Aktif',
  POLICY_DEACTIVATED: 'Polis Tidak Aktif',
};

const policyStatus = {
  POLICY_ISSUED: {
    label: 'Terbit',
    color: 'success'
  },
  POLICY_DEACTIVATED: {
    label: 'Tidak Aktif',
    color: 'default'
  },
  EXPIRED: {
    label: 'Expired',
    color: 'defult'
  },
  PENDING: {
    label: 'Pending',
    color: 'warning'
  },
  SUSPENDED: {
    label: 'Disuspend',
    color: 'danger'
  },
  REQUESTED: {
    label: 'Dalam Proses',
    color: 'warning'
  },
};

const leadStatus = {
  NEW: {
    status: 'New',
    offlineAgentStatus: 'Baru',
    flag: '',
    color: '#1D66DD',
  },
  INCOMPLETE: {
    status: 'Interested',
    offlineAgentStatus: 'Belum Dibayar',
    flag: 'Belum Lengkap',
    color: '#1D66DD',
    greetingMsg: (msg) => WhatsappTemplate(msg, 'CALL_BACK', categoryLib[msg.category.toLowerCase()]),
  },
  CALL_BACK: {
    status: 'Call back',
    offlineAgentStatus: 'Baru',
    flag: '',
    color: '#1D66DD',
    greetingMsg: (msg) => WhatsappTemplate(msg, 'CALL_BACK', categoryLib[msg.category.toLowerCase()]),
  },
  ON_PROCESS: {
    status: 'Sale Unpaid',
    offlineAgentStatus: 'Belum Dibayar',
    flag: 'Belum Dibayar',
    color: '#FAAF40',
  },
  SUSPENDED: {
    status: 'Suspended',
    offlineAgentStatus: 'Sudah Dibayar',
    flag: 'Belum Dibayar, Butuh Direvisi',
    color: '#E81A1A',
  },
  PAID_SUSPENDED: {
    status: 'Suspended',
    offlineAgentStatus: 'Sudah Dibayar',
    flag: 'Sudah Dibayar, Butuh Direvisi',
    color: '#E81A1A',
  },
  AWAITING_ISSUANCE: {
    status: 'Suspended',
    offlineAgentStatus: 'Sudah Dibayar',
    flag: 'Sudah Dibayar, Belum Lengkap',
    color: '#E81A1A',
  },
  REJECTED: {
    status: 'Rejected',
    color: '#c2c2c2',
  },
  CLOSED: {
    status: 'Closed',
    offlineAgentStatus: 'Selesai',
    color: '#1EC494',
  },
  UNREACHABLE: {
    status: 'Tidak Diangkat',
    color: '#1d66dd',
  }
};

const leadStatusGroup = {
  'New': ['NEW'],
  'Tidak Diangkat': ['UNREACHABLE'],
  'Call back': ['CALL_BACK'],
  'Interested': ['INCOMPLETE'],
  'Sale Unpaid': ['ON_PROCESS'],
  'Suspended': ['SUSPENDED', 'AWAITING_ISSUANCE', 'PAID_SUSPENDED'],
  'Renewal & Recurring': ['RENEWAL'],
};

const offlineAgentLeadStatusGroup = {
  'Baru' : ['NEW', 'CALL_BACK'],
  'Belum Dibayar': ['INCOMPLETE', 'ON_PROCESS'],
  'Sudah Dibayar': ['SUSPENDED', 'AWAITING_ISSUANCE', 'PAID_SUSPENDED'],
  'Selesai': ['CLOSED'],
  'Renewal & Recurring': ['RENEWAL'],
};

const getBenefitsValues = () => {
  const values = [];
  for (let index = 5000000; index <= 50000000; index++) {
    if (index % 5000000 === 0) {
      values.push({
        label: idr(index),
        value: index
      });
    }
  }
  return values;
}

const benefitsQuantity = [
  {
    label: '1 Orang',
    value: 1,
  },
  {
    label: '2 Orang',
    value: 2,
  },
  {
    label: '3 Orang',
    value: 3,
  },
  {
    label: '4 Orang',
    value: 4,
  },
];

const orderStatus = {
  PAYMENT_PENDING: {
    status: 'Payment Pending',
    color: '#1D66DD',
  },
  PAYMENT_PROCESSING: {
    status: 'Payment Processing',
    color: '#1D66DD',
  },
  FULLY_PAID: {
    status: 'Fully Paid',
    color: '#1D66DD',
  },
  PARTIALLY_PAID: {
    status: 'Partially Paid',
    color: '#1D66DD',
  },
  REFUND_COMPLETE: {
    status: 'Refund Complete',
    color: '#1D66DD',
  },
  REFUND_PROCESSING: {
    status: 'Refund Processing',
    color: '#1D66DD',
  },
}

export {
  InsuranceCategory,
  InvoiceStatus,
  Gender,
  GenderPlaceholder,
  Spouse,
  PriceAlias,
  ApplicationStage,
  ApplicationStatus,
  CategoryPremi,
  CancelReason,
  policyStatus,
  leadStatus,
  getBenefitsValues,
  benefitsQuantity,
  orderStatus,
  leadStatusGroup,
  offlineAgentLeadStatusGroup,
};
